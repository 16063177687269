/**
 * Our helper for local links
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */

import React from 'react';
import {Globals} from './Store';

/**
 * Creates a local link from an url
 *
 * @param {string} text
 * @returns {string} The filtered text
 */
const ReplaceWpLinks = (text) => {
    if ('string' != typeof text) {
        return text;
    }
    const {wpUrl} = React.useContext(Globals);

    while (text.includes(`${wpUrl}/app/uploads/`)) {
        text = text.replace(`${wpUrl}/app/uploads/`, '/fs/');
    }

    while (text.includes(wpUrl)) {
        text = text.replace(wpUrl, '');
    }

    return text;
};

export default ReplaceWpLinks;
