/**
 * Our image-block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import PeritusLink, {PeritusLinkProps} from '../../components/PeritusLink';
import ResponsiveImage, {ResponsiveImageProps} from '../../components/ResponsiveImage';
import * as styles from './image.module.scss';
import LinkIcon from './link.inline.svg';
import classNames from 'classnames';
import Block from 'components/Block';

const Image = ({image, size, caption, imageAlignment, imageIsLinked, link, mobileHidden, inLayout, className, ...props}) => {
    // figure classes
    const figureClassNames = classNames({
        [styles.figure]: true,
        [styles.mobileHidden] : mobileHidden,
        [className]: true,
    });

    // image-classes
    const imageClassNames = classNames({
        [styles.image]: true,
        [styles[`size${size}`]]: styles[`size${size}`] && !imageIsLinked,
        [styles[imageAlignment]]: styles[imageAlignment] && 'center' !== imageAlignment,
    });

    // Image element
    image = <ResponsiveImage className={imageClassNames} image={image}/>;
    if (imageIsLinked) {
        const linkClassNames = classNames({
            [styles.link]: true,
            [styles.image]: true,
            [styles[`size${size}`]]: !!styles[`size${size}`],
            [styles[imageAlignment]]: styles[imageAlignment] && 'center' !== imageAlignment,
        });

        image = <PeritusLink className={linkClassNames} {...link}>
            {image}
            <div className={styles.overlay}>
                {React.createElement(LinkIcon, {
                    className: styles.overlaySvg,
                })}
                <span className={styles.overlayText}>{link.linktext}</span>
            </div>
        </PeritusLink>
    }

    caption = caption ? <figcaption className={styles.caption}>{caption}</figcaption> : '';

    return (<Block Parent={`figure`} className={figureClassNames} {...props}>
        {image}
        {caption}
    </Block>);
};

Image.propTypes = {
    image: PropTypes.shape(ResponsiveImageProps).isRequired,
    size: PropTypes.string.isRequired,
    caption: PropTypes.string,
    imageAlignment: PropTypes.string,
    imageIsLinked: PropTypes.bool,
    link: PropTypes.shape(PeritusLinkProps),
    mobileHidden: PropTypes.bool,
    inLayout: PropTypes.bool,
    className: PropTypes.string,
};

Image.defaultProps = {
    caption: '',
    imageAlignment: 'center',
    imageIsLinked: false,
    link: {},
    mobileHidden: false,
    inLayout: false,
    className: '',
};

export default Image;
