// extracted by mini-css-extract-plugin
export var figure = "image-module--figure--GTEbi";
export var mobileHidden = "image-module--mobile-hidden--okcxa";
export var image = "image-module--image--OXXwc";
export var link = "image-module--link--lz0Z6";
export var overlayText = "image-module--overlay-text--nl6Ht";
export var overlaySvg = "image-module--overlay-svg--+Xj20";
export var overlay = "image-module--overlay--iymaf";
export var size2 = "image-module--size-2--+i2QS";
export var size4 = "image-module--size-4--oes0e";
export var right = "image-module--right--ofL-4";
export var caption = "image-module--caption--sv4OI";
export var left = "image-module--left--qxEI3";