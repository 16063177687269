/**
 * Our special blog-hero
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveImage, {ResponsiveImageProps} from '../ResponsiveImage';
import * as styles from './single-blog.module.scss';
import Block from '../Block';

const Hero = ({title, excerpt, thumb, date, dateString}) => {
    return (<Block className={styles.hero}>
        <ResponsiveImage className={styles.image} image={thumb}/>
        <div className={styles.info}>
            <time className={styles.date} dateTime={date}>{dateString}</time>
            <h1 className={styles.title}>{title}</h1>
            <p>{excerpt}</p>
        </div>
    </Block>);
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    thumb: PropTypes.shape(ResponsiveImageProps),
    date: PropTypes.string.isRequired,
    dateString: PropTypes.string.isRequired,
};

export default Hero;
